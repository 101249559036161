import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

export const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

export const bannerAreaIO = () => {
    const getBannerSize = (sizeList: string) => sizeList?.split(',')

    return {
        getBannerSize
    }
}

export type BannerAreaIO = ReturnType<typeof bannerAreaIO>
