import { useDeviceContext } from '@contexts/device'
import { CampaignData } from '@store/modules/home/types'
import { CarouselDesktop } from './desktop'
import { CarouselMobile } from './mobile'

type CarouselProps = {
    banners: CampaignData[]
}

export const Carousel = ({ banners }: CarouselProps): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return isDesktop ? (
        <CarouselDesktop banners={banners} />
    ) : (
        <CarouselMobile banners={banners} />
    )
}
