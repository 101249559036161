type BannerContent = { faixaMobileTop: [ContentInfo, ToggleModes] }

type ContentInfo = {
    background: string
    img: string
    link: string
    newtab: boolean
    version: string
}

type ToggleModes = 'on' | 'off'

export const formatTopBannerContent = (content: string | undefined) => {
    if (content) {
        try {
            const formattedContent: BannerContent = JSON.parse(content)
            const { img, version } = formattedContent.faixaMobileTop[0]
            formattedContent.faixaMobileTop[0].img = formatImgUrl(img, version)

            return formattedContent
        } catch (err) {
            console.error('Error parsing top banner content')
            return undefined
        }
    }
    return undefined
}

const formatImgUrl = (img: string, version: string) => {
    return `https://www.efacil.com.br/wcsstore/AuroraStorefrontAssetStore/topo/${img}?v=${version}`
}
