import { useCallback, useEffect, useState } from 'react'

const SMALL_BANNER_HEIGHT = 190
const BIG_BANNER_HEIGHT = 364

export const carouselIO = () => {
    const [activeStep, setActiveStep] = useState(0)
    const [finishedLoading, setFinishedLoading] = useState(false)

    useEffect(() => {
        function startAutoPlay() {
            setTimeout(() => setFinishedLoading(true))
        }

        const loadContentCheckInterval = setInterval(() => {
            if (document.readyState === 'complete') {
                startAutoPlay()
                clearInterval(loadContentCheckInterval)
            }
        }, 5000)
    }, [])

    const handleAutoStepChange = (step: number) => {
        finishedLoading && setActiveStep(step)
    }

    const handleStepChange = useCallback(
        (step: number, numberOfBanners: number) => {
            if (step > numberOfBanners - 1 || step < 0) {
                return setActiveStep(0)
            }
            setActiveStep(step)
        },
        []
    )

    /**
     * Deixa de renderizar as imagens passadas a partir da segunda posição
     */
    const isOnRange = (active: number, index: number) => {
        return Math.abs(active - index) <= 2
    }

    const getBannerSize = (isSmallBannerSize: boolean) => {
        return isSmallBannerSize ? SMALL_BANNER_HEIGHT : BIG_BANNER_HEIGHT
    }

    const handleStepDotClick = (index: number) => {
        setActiveStep(index)
    }

    return {
        activeStep,
        finishedLoading,
        handleAutoStepChange,
        handleStepChange,
        isOnRange,
        getBannerSize,
        handleStepDotClick
    }
}

export type CarouselIO = ReturnType<typeof carouselIO>
