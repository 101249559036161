import { useHideOnScroll } from '@hooks/useHideOnScroll'
import { useLoadedEffect } from '@hooks/useLoadedEffect'
import { getESpotContentThunk } from '@store/modules/espots/thunk'
import { ESpot } from '@store/modules/espots/types'
import { useAppDispatch } from '@store/modules/store'
import { useState } from 'react'
import { formatTopBannerContent } from './utils'
import { TopBannerProps } from './view'

export const topBannerIO = ({
    topBannerStaticContent,
    autoLoadBannerData
}: TopBannerProps) => {
    const [topBannerContent, setTopBannerContent] = useState<string>()
    const dispatch = useAppDispatch()
    const { hideComponent } = useHideOnScroll()

    useLoadedEffect(() => {
        if (autoLoadBannerData && !topBannerStaticContent)
            dispatch(
                getESpotContentThunk(ESpot.TOP_BANNER, {
                    onSuccess: setTopBannerContent
                })
            )
    }, [])

    const bannerContent = topBannerStaticContent ?? topBannerContent
    const formattedContent = formatTopBannerContent(bannerContent)
    const [bannerInfo, bannerToggle] = formattedContent?.faixaMobileTop ?? []

    return {
        bannerInfo,
        bannerToggle,
        hideComponent
    }
}

export type TopBannerIO = ReturnType<typeof topBannerIO>
