import { ProductRatingListScript } from '@components/trustvox/ProductRatingListScript'
import { HomeData } from '@store/modules/home/types'
import { SeoData } from '@store/modules/url/types'
import { NextSeo, OrganizationJsonLd, SiteLinksSearchBoxJsonLd } from 'next-seo'
import Head from 'next/head'
import { Routes } from '@service/routes'
import { getCanonical } from '@seo/utils/defaultSeo'

type HomeSeoProps = {
    homeData: HomeData
    seoData?: SeoData | null
}

export const HomeSeoView = ({ seoData }: HomeSeoProps): JSX.Element => {
    return (
        <>
            {seoData && (
                <NextSeo
                    title={seoData.page.title}
                    description={seoData.page.metaDescription}
                    canonical={getCanonical(Routes.home)}
                    additionalMetaTags={[
                        {
                            name: 'keyword',
                            content: seoData.page.metaKeyword
                        }
                    ]}
                    additionalLinkTags={[
                        {
                            rel: 'preconnect',
                            href: `${process.env.NEXT_PUBLIC_OLD_SITE_URL}`
                        },
                        {
                            rel: 'dns-prefetch',
                            href: `${process.env.NEXT_PUBLIC_OLD_SITE_URL}`
                        }
                    ]}
                />
            )}
            <Head>
                <ProductRatingListScript />
            </Head>
            <OrganizationJsonLd
                type="Corporation"
                name="eFácil"
                url={`${process.env.NEXT_PUBLIC_CURRENT_SITE_URL}`}
                logo="https://www.efacil.com.br/wcsstore/AuroraStorefrontAssetStore/Attachment/BANNER/HOME/logoEfacil1_branca.png"
            />
            <SiteLinksSearchBoxJsonLd
                url={`${process.env.NEXT_PUBLIC_CURRENT_SITE_URL}`}
                potentialActions={[
                    {
                        target: 'https://www.efacil.com.br/loja/busca?searchTerm',
                        queryInput: 'search_term_string'
                    }
                ]}
            />
        </>
    )
}
