export enum ESpot {
    TOP_BANNER = 'JSON_FAIXA_MOBILE_NOVO',
    WARRANTY = 'GarantiaCondicoes_API'
}

export enum CommonESpot {
    TermsOfUse = 'TermsOfUse_Content',
    PrivacyPolicy = 'PrivacyPolicyCenter_Content',
    ExchangesAndReturnPolicies = 'PoliticaTrocaDevolucaoMidle_LS'
}

export const SpecialEspotNames = [
    'ENTREGAEXPRESSA',
    'GARANTIAESTENDIDA',
    'AVALIE',
    'LP-GUIA-DE-SEGURANCA',
    'QUEMSOMOS',
    'CUPONSEFACIL',
    'LP_PONTUACAO'
] as const
