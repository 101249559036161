import { Stack } from '@components/Stack'
import { StepperCarousel } from '@components/StepperCarousel'
import { SwipeableButton } from '@components/SwipeableButton'
import styled from 'styled-components'
import { AutoPlaySwipeableViews } from '../../BannerArea/io'

export const Container = styled(Stack)`
    margin-top: 16px;
    max-width: 1280px;
    position: relative;

    > div {
        border-radius: 6px;
    }
`

export const ContentImage = styled.div`
    overflow-y: hidden;
`

export const Stepper = styled(StepperCarousel)`
    position: absolute;
    bottom: 8px;
`

export const ArrowRightButton = styled(SwipeableButton)`
    position: absolute;
    z-index: 10;
    bottom: 40%;
    right: 24px;
    width: 40px;
    height: 40px;
`

export const ArrowLeftButton = styled(SwipeableButton)`
    position: absolute;
    z-index: 10;
    bottom: 40%;
    left: 24px;
    width: 40px;
    height: 40px;
`

export const CustomAutoPlaySwipeable = styled(AutoPlaySwipeableViews)`
    width: 100%;
`
