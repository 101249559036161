import styled, { css } from 'styled-components'

interface ImageContentProps {
    $backgroundColor: string
    $hide: boolean
}

const hideMenuBar = css`
    height: 0;
    opacity: 0;
`

export const Container = styled.div<ImageContentProps>`
    height: 38px;
    cursor: pointer;
    background-color: ${props => props.$backgroundColor};
    position: relative;
    ${({ $hide }) => $hide && hideMenuBar};
    transition: 0.2s;
`
