import { DepartmentSliderProps } from './types'
import { useDeviceContext } from '@contexts/device'
import dynamic from 'next/dynamic'

const DepartmentSliderDesktop = dynamic<DepartmentSliderProps>(
    () => import('./desktop').then(module => module.DepartmentSliderDesktop),
    { ssr: true }
)
const DepartmentSliderMobile = dynamic<DepartmentSliderProps>(
    () => import('./mobile').then(module => module.DepartmentSliderMobile),
    { ssr: true }
)

export const DepartmentSliderView = ({
    departments
}: DepartmentSliderProps): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return isDesktop ? (
        <DepartmentSliderDesktop departments={departments} />
    ) : (
        <DepartmentSliderMobile departments={departments} />
    )
}
