import { BaseDataLayerObject, PageDataLayer } from './types'
import { parseCookies } from 'nookies'
import packageData from '../../../package.json'

export const dataLayerPush = (
    pageDataLayer?: PageDataLayer,
    hasBaseDataLayer = true
) => {
    const typedPageWindow = window as any
    typedPageWindow.dataLayer = typedPageWindow.dataLayer || []

    try {
        if (pageDataLayer?.ecommerce) {
            typedPageWindow.dataLayer.push(clearEcommerce())
        }
        const baseData = hasBaseDataLayer && baseDataLayer()
        const formatedDataLayer = { ...pageDataLayer, ...baseData }
        typedPageWindow.dataLayer.push(formatedDataLayer)
    } catch (error) {
        console.warn(error)
    }
}

export const clearEcommerce = (): PageDataLayer => {
    return {
        ecommerce: null
    }
}

export const baseDataLayer = (): BaseDataLayerObject => {
    return {
        storeId: parseCookies().StoreId,
        storeName: parseCookies().storeName,
        visitorId: parseCookies().userID,
        visitorName: parseCookies().userName,
        visitorEmail: parseCookies().userMail,
        visitorPhone: parseCookies().phone1,
        visitorGender: parseCookies().gender,
        visitorDateOfBirth: parseCookies().dateOfBirth,
        visitorEmailPreference: parseCookies().userMailPreference,
        visitorState: '',
        channel: parseCookies().UserSegment,
        app_name: packageData.name,
        app_version: packageData.version
    }
}
