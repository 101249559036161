import styled from 'styled-components'

export const StepperContainer = styled.div`
    direction: flex;
    align-self: center;
`

type StepDotProps = {
    isSelected: boolean
    grow?: boolean
}

export const StepDot = styled.div<StepDotProps>`
    cursor: pointer;
    width: ${({ isSelected, grow }) => (isSelected && grow ? '24px' : '8px')};
    height: 8px;
    border-radius: 8px;
    background-color: ${({ isSelected, theme }) =>
        isSelected ? theme.colors.secondary[500] : theme.colors.gray[200]};
`
