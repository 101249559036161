import { CustomImage } from '@components/CustomImage'
import { CustomLink } from '@components/CustomLink'
import { useDeviceContext } from '@contexts/device'
import { IOProps } from 'react-compose-io'
import { TopBannerIO } from './io'
import * as Styled from './styles'
import { ErrorBoundary } from 'react-error-boundary'

export type TopBannerProps = {
    topBannerStaticContent?: string | null
    autoLoadBannerData?: boolean
}

export const TopBannerView = ({
    _io
}: IOProps<TopBannerIO, TopBannerProps>): JSX.Element => {
    if (!_io.bannerInfo || _io.bannerToggle !== 'on') return <></>
    const { isDesktop } = useDeviceContext()

    return (
        <ErrorBoundary
            fallback={<></>}
            onError={error => console.error('Client Side Error:', error)}
        >
            <Styled.Container
                $backgroundColor={_io.bannerInfo.background}
                $hide={isDesktop ? _io.hideComponent : false}
            >
                <CustomLink
                    href={_io.bannerInfo.link}
                    target={_io.bannerInfo.newtab ? '_blank' : '_self'}
                    aria-label="acessar conteúdo"
                >
                    <CustomImage
                        priority
                        src={_io.bannerInfo.img}
                        layout="fill"
                        objectFit="contain"
                        alt="banner informativo"
                        data-testid="top-banner"
                    />
                </CustomLink>
            </Styled.Container>
        </ErrorBoundary>
    )
}
