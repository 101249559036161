import Head from 'next/head'
import React, { useEffect } from 'react'
import { AdSlot, GooglePublisherTagProps } from './types'

const GPT_STANDARD_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'

let adSlots: AdSlot = {}

if (typeof window !== 'undefined') {
    window.googletag = window.googletag || { cmd: [] }

    googletag.cmd.push(() => {
        googletag.pubads().disableInitialLoad()
        googletag.pubads().enableSingleRequest()
        googletag.enableServices()
    })
}

function InitializeGPT() {
    adSlots = {}

    return <script src={GPT_STANDARD_URL} async />
}

function DefineAdSlot({ adUnit, size, slotId }: GooglePublisherTagProps) {
    useEffect(() => {
        googletag.cmd.push(() => {
            const slot = googletag.defineSlot(
                `/22481043790/eFacil/${adUnit}/`,
                size as googletag.GeneralSize,
                slotId
            )

            if (slot) {
                slot.addService(googletag.pubads())
                googletag.display(slot)
                adSlots[slotId] = slot
            }
        })

        return () => {
            googletag.cmd.push(() => {
                if (adSlots[slotId]) {
                    googletag.destroySlots([adSlots[slotId]])
                    delete adSlots[slotId]
                }
            })
        }
    }, [])

    return (
        <div
            id={slotId}
            className="flex justify-center"
            style={getMinimumSlotSize(size)}
        ></div>
    )
}

function RequestAds() {
    useEffect(() => {
        googletag.cmd.push(() => {
            const slots = Object.values(adSlots)
            googletag.pubads().refresh(slots)
        })
    }, [])
}

function getMinimumSlotSize(size: number[]) {
    const maxValue = Number.MAX_VALUE

    let minWidth = Number.MAX_VALUE
    let minHeight = Number.MAX_VALUE

    if (Array.isArray(size)) {
        const sizes =
            size.length <= 2 && !Array.isArray(size[0]) ? [size] : size

        for (const size of sizes) {
            if (Array.isArray(size)) {
                minWidth = Math.min(size[0], minWidth)
                minHeight = Math.min(size[1], minHeight)
            }
        }
    }

    return minWidth < maxValue && minHeight < maxValue
        ? { minWidth: `${minWidth}px`, minHeight: `${minHeight}px` }
        : { minWidth: '50%' }
}

export const GooglePublisherTag = ({
    adUnit,
    size,
    slotId
}: GooglePublisherTagProps): JSX.Element => {
    return (
        <>
            <Head>
                <InitializeGPT />
            </Head>
            <DefineAdSlot adUnit={adUnit} size={size} slotId={slotId} />
            {RequestAds()}
        </>
    )
}
