import { DetailedProductPrice } from '@store/modules/product/types'

export type GTMFormatedProduct = {
    available?: string
    brand?: string
    category?: string
    categoryDetails?: CategoryDetailsGTMProps[]
    fullImage?: string
    fullPrice?: string | number
    id?: string
    installment?: { count?: string | number; price?: string | number }
    name?: string
    price?: string | number
    productPartNumber?: string
    sku?: string
    parentSku?: string
    thumbnail?: string
    wcsId?: string
    quantity?: number
    adjustment?: number
}

export type GTMFormatedCartProduct = GTMFormatedProduct & {
    quantity?: number
}

export type ProductEcommerceGtm = {
    item_id?: string | number
    item_name?: string
    affiliation?: string
    index?: number
    item_brand?: string
    item_category?: string
    item_category2?: string
    item_list_id?: string | number
    item_list_name?: string
    item_variant?: string
    quantity?: number
    precoPor?: string | number
    price?: string | number | null
}

export type CartEcommerceGtm = {
    currency: string
    value: number
    coupon: string
    items: ProductEcommerceGtm[]
}

export type CheckoutEcommerceGtm = CartEcommerceGtm & {
    payment_type: string
}

export type CheckoutDeliveryEcommerceGtm = CartEcommerceGtm & {
    shipping_tier: string
}

export type ProductBannerGtm = {
    banner_url?: string
    banner_creative?: string
    banner_id?: string | number
    banner_position?: string | number
    banner_name?: string
    banner_element?: string
    banner_image_base?: string
    banner_image?: string
}

export type ProductGtm = {
    disponivel?: boolean
    marca?: string
    categoria?: string
    subcategoria?: string
    idCategoria?: string
    idSubcategoria?: string
    thumbnail?: string
    fullImage?: string
    fullPrice?: string | number
    preco: Partial<DetailedProductPrice>
    idProdutoPai?: string
    idProduto?: string
    sku?: string
    skuPai?: string
    nome: string
    contagemParcelas?: string | number
    precoParcelas?: string | number
    precoPor?: string | number
}

export type BaseDataLayerObject = {
    storeName: DataLayerItem
    channel: DataLayerItem
    storeId: DataLayerItem
    visitorDateOfBirth: DataLayerItem
    visitorEmail: DataLayerItem
    visitorEmailPreference: DataLayerItem
    visitorGender: DataLayerItem
    visitorId: DataLayerItem
    visitorName: DataLayerItem
    visitorPhone: DataLayerItem
    visitorState: DataLayerItem
    app_name: DataLayerItem
    app_version: DataLayerItem
}

export enum PageTypes {
    home = 'Home',
    login = 'Log In',
    search = 'Pesquisa',
    hotsite = 'Pesquisa',
    register = 'Cadastro',
    cartDetail = 'DetalheCarrinho',
    departmentCategory = 'Categoria',
    productDetail = 'Detalhe_Produto',
    orderConclusion = 'Conclusao_Pedido',
    departmentSubCategory = 'SubCategoria',
    shippingCheckout = 'Etapa_CheckOut_Entrega',
    paymentCheckout = 'Etapa_CheckOut_Pagamento'
}

export type PageDataLayer = Partial<BaseDataLayerObject> & {
    Payment?: PaymentItem[]
    categoryId?: string
    categoryName?: string
    event?: string
    pageType?: PageTypes
    url?: string
    searchTerm?: string | string[]
    transactionProducts?: GTMFormatedProduct[] | GTMFormatedCartProduct[]
    ecommerce?:
        | {
              items?: ProductEcommerceGtm[]
          }
        | CartEcommerceGtm
        | null
    registrationType?: string
    transactionId?: number
    transactionShipping?: number
    transactionSubtotal?: number
    transactionTotal?: number
    transactionAffiliation?: string | null
    transactionTotalAdjustment?: number
    actionField?: any
    deliveryAddress?: {
        address?: string
        address_id?: string
        city?: string
        contry?: string
        name?: string
        telContato?: string
        telEmail?: string
        zipcode?: string
    }
    screen_name?: PageTypes
    bannerDetail?: ProductBannerGtm[]
}

type PaymentItem = {
    Price?: number
    Payment_Type?: string
    Payment_Code?: string
}

type DataLayerItem = string | undefined

type CategoryDetailsGTMProps = {
    id?: string | number
    name?: string
}

export type CategoryDetailsProps = {
    categoria?: string
    subcategoria?: string
    idCategoria?: string | number
    idSubcategoria?: string | number
}
