import { ArrowLeftIcon } from '@assets/svg/ArrowIcons/ArrowLeftIcon'
import { ArrowRightIcon } from '@assets/svg/ArrowIcons/ArrowRightIcon'
import * as Styled from './styles'
import { SwipeableButtonProps } from './types'

export const SwipeableButtonView = ({
    direction,
    disabled,
    size = 50,
    ...props
}: SwipeableButtonProps) => {
    return (
        <Styled.ArrowButton {...props} $size={size}>
            {direction === 'right' ? (
                <ArrowRightIcon disabled={disabled} />
            ) : (
                <ArrowLeftIcon disabled={disabled} />
            )}
        </Styled.ArrowButton>
    )
}
