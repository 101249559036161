import { ThunkOptions } from '@store/types'
import { handleErrorResponse } from '@store/utils'
import { AppThunk } from '../store'
import { getESpotContent } from './services'
import { ESpot } from './types'

export const getESpotContentThunk =
    (
        eSpotType: ESpot,
        { onSuccess, onError }: ThunkOptions<string>
    ): AppThunk =>
    async () => {
        try {
            const content = await getESpotContent(eSpotType)
            onSuccess && onSuccess(content)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }
