import { Stack } from '@components/Stack'
import * as Styled from './styles'

type StepperCarouselProps = {
    numberOfSteps: number
    activeStep: number
    growSelectedSep?: boolean
    onDotClick: (index: number) => void
    className?: string
}

export function StepperCarouselView({
    numberOfSteps,
    activeStep,
    growSelectedSep,
    onDotClick,
    ...props
}: StepperCarouselProps) {
    return (
        <Styled.StepperContainer {...props}>
            <Stack direction="row" spacing={4}>
                {Array.from({ length: numberOfSteps }).map((_, index) => (
                    <Styled.StepDot
                        key={index}
                        onClick={() => onDotClick(index)}
                        grow={growSelectedSep}
                        isSelected={index === activeStep}
                    />
                ))}
            </Stack>
        </Styled.StepperContainer>
    )
}
