import { Stack } from '@components/Stack'
import { DESKTOP_SIZE } from '@constants/device'
import styled, { css } from 'styled-components'

export const ImageContainer = styled.div`
    max-width: 100%;
    height: ${({ theme }) => (theme.isDesktop ? '100%' : 'auto')};
    padding: ${({ theme }) => (theme.isDesktop ? 'auto' : '16px 0 0')};
    span {
        border-radius: 4px;
    }
`

export const AlignContainer = styled(Stack).attrs(({ theme }) => ({
    fullWidth: true,
    alignItems: theme.isDesktop ? 'center' : 'auto',
    justifyContent: theme.isDesktop ? 'center' : 'auto'
}))``

const containerDesktop = css`
    margin-top: 16px;
    gap: 16px;
    width: 100%;
    max-width: ${DESKTOP_SIZE}px;
    flex-wrap: wrap;
    padding: auto;
`

const containerMobile = css`
    margin: auto;
    gap: auto;
    width: 100%;
    max-width: auto;
    flex-wrap: auto;
    padding: 0 16px 16px;
`

export const Container = styled(Stack).attrs(({ theme }) => ({
    direction: theme.isDesktop ? 'row' : 'column',
    alignItems: theme.isDesktop ? 'center' : 'auto',
    justifyContent: theme.isDesktop ? 'center' : 'auto'
}))`
    ${({ theme }) => (theme.isDesktop ? containerDesktop : containerMobile)};
` as typeof Stack
