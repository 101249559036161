import { ArrowIconProps } from './types'

export const ArrowLeftIcon = ({
    disabled = false,
    width = 24,
    height = 24
}: ArrowIconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_19137_17061)">
            <path
                d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                fill={disabled ? '#c4c4c4' : '#009DFF'}
            />
        </g>
        <defs>
            <clipPath id="clip0_19137_17061">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
