import { Stack } from '@components/Stack'
import { StepperCarousel } from '@components/StepperCarousel'
import styled from 'styled-components'
import { AutoPlaySwipeableViews } from '../../BannerArea/io'

export const StyledAutoPlaySwipeable = styled(AutoPlaySwipeableViews)`
    width: 100%;
`

export const Container = styled(Stack)`
    flex-direction: column;
    margin-top: -10px;
    padding: 0 16px;

    > div {
        border-radius: 4px;
    }
`

export const ContentImage = styled.div`
    overflow-y: hidden;
`

export const Stepper = styled(StepperCarousel)`
    padding: 16px 0 0;
`
