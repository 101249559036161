import { ArrowIconProps } from './types'

export const ArrowRightIcon = ({
    disabled = false,
    width = 24,
    height = 24
}: ArrowIconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_19137_4742)">
            <path
                d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
                fill={disabled ? '#c4c4c4' : '#009DFF'}
            />
        </g>
        <defs>
            <clipPath id="clip0_19137_4742">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
