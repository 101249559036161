import { AdaptiveContainer } from '@components/AdaptiveContainer'
import { IOProps } from 'react-compose-io'
import { HomeIO } from './io'
import { HomeStaticProps } from './static/types'
import * as Styled from './styles'
import { CookiesMessageModal } from './components/CookiesMessageModal'

export const HomeView = ({
    _io,
    homeLayout
}: IOProps<HomeIO, HomeStaticProps>): JSX.Element => {
    return (
        <AdaptiveContainer>
            <Styled.Container>
                {!!homeLayout && _io.handleRenderHomeLayout(homeLayout)}
            </Styled.Container>
            <CookiesMessageModal />
        </AdaptiveContainer>
    )
}
