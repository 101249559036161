import api from '@service/index'
import { CommonESpot, ESpot } from './types'

export const getESpotContent = async (eSpotType: ESpot) => {
    return api
        .get<{ conteudo: string }>(`espots/${eSpotType}`)
        .then(res => res.data.conteudo)
}

export const getTopBannerContent = async () => {
    try {
        return await getESpotContent(ESpot.TOP_BANNER)
    } catch (error) {
        console.error('Error fetching top banner content')
    }
    return null
}

export const getSpecialEspots = async (espot: string) => {
    return api
        .get<{ conteudo: string }>(`espots/SPECIALESPOT_CONTENT1_${espot}`)
        .then(res => {
            return res.data.conteudo
        })
}

export const getCommonEspot = async (param: CommonESpot) => {
    return api.get<{ conteudo: string }>(`espots/${param}`).then(res => {
        return res.data.conteudo
    })
}
