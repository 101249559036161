import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 32px;
    margin-bottom: 24px;
    width: 100%;

    > div:first-child {
        margin-top: 0;
    }
`
