import { CustomImage } from '@components/CustomImage'
import { CustomLink } from '@components/CustomLink'
import { BLUR_DATA_URL } from '@constants/common'
import { getRedirectLink } from '@features/home/utils'
import { removeAccents } from '@utils/common'
import { IOProps } from 'react-compose-io'
import { BannerAreaIO } from './io'
import * as Styled from './styles'
import { BannerAreaProps } from './types'

export const BannerAreaView = ({
    banners,
    _io
}: IOProps<BannerAreaIO, BannerAreaProps>): JSX.Element => {
    if (!banners || banners.length === 0) return <></>

    return (
        <Styled.AlignContainer>
            <Styled.Container>
                {banners?.map(
                    (banner, index) =>
                        Boolean(banner.tamanho) &&
                        banner.tamanho.length >= 2 && (
                            <Styled.ImageContainer key={index}>
                                <CustomLink
                                    href={getRedirectLink(
                                        banner.acaoClick,
                                        removeAccents(banner.link)
                                    )}
                                >
                                    <CustomImage
                                        id={`btn_home_area_` + banner.nome}
                                        placeholder="blur"
                                        blurDataURL={BLUR_DATA_URL}
                                        src={banner.imagem.url}
                                        alt={banner.imagem.descricao}
                                        width={Number(
                                            _io.getBannerSize(banner.tamanho)[0]
                                        )}
                                        height={Number(
                                            _io.getBannerSize(banner.tamanho)[1]
                                        )}
                                        loading="lazy"
                                    />
                                </CustomLink>
                            </Styled.ImageContainer>
                        )
                )}
            </Styled.Container>
        </Styled.AlignContainer>
    )
}
