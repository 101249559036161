import { CustomImage } from '@components/CustomImage'
import { CustomLink } from '@components/CustomLink'
import { BLUR_DATA_URL } from '@constants/common'
import { getRedirectLink } from '@features/home/utils'
import { CampaignData } from '@store/modules/home/types'
import { removeAccents } from '@utils/common'
import { IOProps } from 'react-compose-io'
import { CarouselIO } from '../io'
import * as Styled from './styles'

type CarouselProps = {
    banners: CampaignData[]
}

export const CarouselDesktopView = ({
    banners,
    _io
}: IOProps<CarouselIO, CarouselProps>): JSX.Element => {
    const { activeStep, handleAutoStepChange, handleStepChange } = _io

    return (
        <Styled.Container aria-hidden="true" data-testid="carousel">
            <Styled.ArrowLeftButton
                onClick={() => handleStepChange(activeStep - 1, banners.length)}
            />
            <Styled.CustomAutoPlaySwipeable
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleAutoStepChange}
                enableMouseEvents={true}
                interval={7000}
            >
                {banners?.map((banner, index) => (
                    <Styled.ContentImage
                        key={index}
                        id={'homeBannerDetail_' + index}
                    >
                        {_io.isOnRange(activeStep, index) && (
                            <CustomLink
                                href={getRedirectLink(
                                    banner.acaoClick,
                                    removeAccents(banner.link)
                                )}
                            >
                                <CustomImage
                                    id={`btn_home_carousel_` + index}
                                    placeholder="blur"
                                    blurDataURL={BLUR_DATA_URL}
                                    src={banner.imagem.url}
                                    alt={banner.imagem.descricao}
                                    width="1280"
                                    height="300"
                                    layout="responsive"
                                    priority={index === 0}
                                    loading={index === 0 ? 'eager' : 'lazy'}
                                />
                            </CustomLink>
                        )}
                    </Styled.ContentImage>
                ))}
            </Styled.CustomAutoPlaySwipeable>
            <Styled.Stepper
                numberOfSteps={banners.length}
                activeStep={activeStep}
                onDotClick={_io.handleStepDotClick}
                growSelectedSep
            />
            <Styled.ArrowRightButton
                direction="right"
                onClick={() => handleStepChange(activeStep + 1, banners.length)}
            />
        </Styled.Container>
    )
}
