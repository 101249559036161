import styled from 'styled-components'

type ArrowButtonStyledProps = {
    $size: number
}

export const ArrowButton = styled.button<ArrowButtonStyledProps>`
    display: flex;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    background-color: ${({ theme }) => theme.colors.white};
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
    cursor: pointer;
`
